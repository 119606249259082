export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/LoginView.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/quick-order',
    name: 'QuickOrder',
    component: () => import('@/views/customer/quick-order/QuickOrder.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/register',
    name: 'CustomerSignup',
    component: () => import('@/views/auth/CustomerSignup.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/writers/apply',
    name: 'ApplicantSignup',
    component: () => import('@/views/auth/ApplicantSignup.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/password/reset',
    name: 'RequestPasswordReset',
    component: () => import('@/views/auth/RequestPasswordReset.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('@/views/common/UnsubscribePage.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/companies/:company_id/invitations/:token',
    name: 'CompanyInviteeSignup',
    component: () => import('@/views/auth/CompanyInviteeSignup.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/invitations/:token',
    name: 'InviteeSignup',
    component: () => import('@/views/auth/InviteeSignup.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/free-tools/brief-generator',
    name: 'FreeToolsBriefGenerator',
    component: () => import('@/views/common/free-tools/BriefGenerator.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/free-tools/meta-title-description-generator',
    name: 'FreeToolsMetaTitleDescriptionGenerator',
    component: () =>
      import('@/views/common/free-tools/MetaTitleDescriptionGenerator.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/free-tools/content-calendar',
    name: 'FreeToolsContentCalendarGenerator',
    component: () =>
      import('@/views/common/free-tools/ContentCalendarGenerator.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
  {
    path: '/ai-tools/share/:id',
    name: 'SharedAiToolsOutputView',
    component: () => import('@/views/common/SharedAiToolsOutputView.vue'),
    meta: {
      isGuestRoute: true,
    },
  },
];
