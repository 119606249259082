export default [
  {
    path: '/writer/tasks/:id',
    name: 'WriterDocumentEditor',
    component: () => import('@/views/writer/DocumentEditor.vue'),
  },
  {
    path: '',
    name: 'WriterLayout',
    component: () => import('@/views/writer/Layout.vue'),
    children: [
      {
        path: '/writer/home',
        name: 'WriterHome',
        component: () => import('@/views/writer/Home.vue'),
      },
      {
        path: '/writer/notifications',
        name: 'WriterNotifications',
        component: () => import('@/views/writer/ViewNotifications.vue'),
      },
      {
        path: '/writer/activities',
        name: 'WriterActivities',
        component: () => import('@/views/writer/ViewActivities.vue'),
      },
      {
        path: '/writer/projects/:id',
        name: 'WriterViewProject',
        component: () => import('@/views/writer/ViewProject.vue'),
      },
      {
        path: '/writer/projects',
        name: 'WriterViewProjects',
        component: () => import('@/views/writer/ViewProjects.vue'),
      },
      {
        path: '/writer/tasks',
        name: 'WriterViewTasks',
        component: () => import('@/views/writer/ViewTasks.vue'),
      },
      {
        path: '/writer/ratings-and-feedback',
        name: 'RatingsAndFeedback',
        component: () => import('@/views/writer/RatingsAndFeedback.vue'),
      },
      {
        path: '/writer/settings',
        name: 'WriterSettings',
        component: () => import('@/views/writer/Settings.vue'),
      },
      {
        path: '/writer/complete-registration',
        name: 'WriterCompleteRegistration',
        component: () => import('@/views/writer/CompleteRegistration.vue'),
      },
      {
        path: '/writer/revision-tracker',
        name: 'WriterRevisionTracker',
        component: () => import('@/views/writer/RevisionTracker.vue'),
      },
      {
        path: '/writer/first-submissions-tracker',
        name: 'WriterFirstSubmissionsTracker',
        component: () => import('@/views/writer/FirstSubTracker.vue'),
      },
      {
        path: '/writer/payouts',
        name: 'WriterPayoutsDashboard',
        component: () => import('@/views/writer/Payouts.vue'),
      },
      {
        path: '/writer/feeds/:type/:id',
        name: 'WriterViewFeedItem',
        component: () => import('@/views/writer/ViewFeedItem.vue'),
      },
      {
        path: '/writer/availability-calendar',
        name: 'WriterAvailabilityCalendar',
        component: () => import('@/views/writer/AvailabilityCalendar.vue'),
      },
      {
        path: '/writer/sample-articles',
        name: 'WriterSampleArticles',
        component: () => import('@/views/writer/SampleArticlesTracker.vue'),
      },
      {
        path: '/writer/sample-article-creator',
        name: 'WriterSampleArticleCreator',
        component: () => import('@/views/writer/SampleArticleCreator.vue'),
      },
      {
        path: '/writer/my-sample-articles/:id',
        name: 'WriterSampleArticleEditor',
        component: () => import('@/views/writer/SampleArticleEditor.vue'),
      },
      {
        path: '/writer/handbook',
        name: 'WriterHandbookLayout',
        component: () => import('@/views/writer/HandbookLayout.vue'),
        children: [
          {
            path: '',
            name: 'HandbookIndex',
            component: () => import('@/views/writer/HandbookIndex.vue'),
          },
          {
            path: 'welcome',
            name: 'HandbookWelcome',
            component: () => import('@/components/writer/handbook/Welcome.vue'),
          },
          {
            path: 'how-does-the-process-work',
            name: 'HowProcessWork',
            component: () =>
              import('@/components/writer/handbook/HowProcessWork.vue'),
          },
          {
            path: 'skills-and-values-required',
            name: 'SkillsAndValues',
            component: () =>
              import('@/components/writer/handbook/SkillsAndValues.vue'),
          },
          {
            path: 'navigating-the-wittypen-dashboard',
            name: 'NavigatingWittypenDashboard',
            component: () =>
              import(
                '@/components/writer/handbook/NavigatingWittypenDashboard.vue'
              ),
          },
          {
            path: 'navigating-wittypen-editor',
            name: 'NavigatingWittypenEditor',
            component: () =>
              import(
                '@/components/writer/handbook/NavigatingWittypenEditor.vue'
              ),
          },
          {
            path: 'asking-for-work',
            name: 'AskingForWork',
            component: () =>
              import('@/components/writer/handbook/AskingForWork.vue'),
          },
          {
            path: 'how-to-go-about-a-task-that-you-are-assigned',
            name: 'GoForAssignedTask',
            component: () =>
              import('@/components/writer/handbook/GoForAssignedTask.vue'),
          },
          {
            path: 'how-and-when-do-you-get-paid',
            name: 'GetPaid',
            component: () => import('@/components/writer/handbook/GetPaid.vue'),
          },
          {
            path: 'what-can-get-you-removed-from-a-task-project',
            name: 'WhatCanGetYouRemoved',
            component: () =>
              import('@/components/writer/handbook/WhatCanGetYouRemoved.vue'),
          },
          {
            path: 'upgrading-your-skills',
            name: 'UpgradeSkills',
            component: () =>
              import('@/components/writer/handbook/UpgradeSkills.vue'),
          },
          {
            path: 'plagiarism-can-get-you-removed',
            name: 'PlagiarismGetYouRemoved',
            component: () =>
              import(
                '@/components/writer/handbook/PlagiarismGetYouRemoved.vue'
              ),
          },
        ],
      },
    ],
  },
];
