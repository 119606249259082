export default [
  {
    path: '/superadmin/home',
    name: 'SuperadminLayout',
    component: () => import('@/views/superadmin/Layout.vue'),
    children: [
      {
        path: '/superadmin/home',
        name: 'SuperadminHome',
        component: () => import('@/views/superadmin/Home.vue'),
      },
      {
        path: '/superadmin/manage-users',
        name: 'ManageUsers',
        component: () => import('@/views/superadmin/ManageUsers.vue'),
      },
      {
        path: '/superadmin/manage-invitations',
        name: 'ManageInvitations',
        component: () => import('@/views/superadmin/ManageInvitations.vue'),
      },
      {
        path: '/superadmin/login-spoof-tool',
        name: 'LoginSpoofToolPage',
        component: () => import('@/views/superadmin/LoginSpoof.vue'),
      },
      {
        path: '/superadmin/task-completer',
        name: 'TaskCompleterPage',
        component: () => import('@/views/superadmin/TaskCompleter.vue'),
      },
      {
        path: '/superadmin/content-transferer',
        name: 'ContentTransferer',
        component: () => import('@/views/superadmin/ContentTransferer.vue'),
      },
      {
        path: '/superadmin/websocket-tester',
        name: 'WebsocketTester',
        component: () => import('@/views/superadmin/WebsocketTester.vue'),
      },
      {
        path: '/superadmin/settings',
        name: 'SuperadminSettings',
        component: () => import('@/views/superadmin/Settings.vue'),
      },
      {
        path: '/superadmin/strategies',
        name: 'SuperadminStrategies',
        component: () => import('@/views/superadmin/Strategies.vue'),
      },
      {
        path: '/superadmin/templates',
        name: 'SuperadminTemplates',
        component: () => import('@/views/superadmin/Templates.vue'),
      },
    ],
  },
];
