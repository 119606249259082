export default [
  {
    path: '',
    name: 'ApplicantLayout',
    component: () => import('@/views/applicant/Layout.vue'),
    children: [
      {
        path: '/applicant/home',
        name: 'ApplicantHome',
        component: () => import('@/views/applicant/Home.vue'),
      },
      {
        path: '/applicant/basic-details-form',
        name: 'ApplicantBasicDetailsForm',
        component: () => import('@/views/applicant/BasicDetailsForm.vue'),
      },
      {
        path: '/applicant/assessment',
        name: 'ApplicantAssessment',
        component: () => import('@/views/applicant/Assessment.vue'),
      },
      {
        path: '/applicant/sample-article-creator',
        name: 'ApplicantSampleArticleCreator',
        component: () => import('@/views/applicant/SampleArticleCreator.vue'),
      },
      {
        path: '/applicant/my-sample-articles/:id',
        name: 'ApplicantSampleArticleEditor',
        component: () => import('@/views/applicant/SampleArticleEditor.vue'),
      },
      {
        path: '/applicant/settings',
        name: 'ApplicantSettings',
        component: () => import('@/views/applicant/Settings.vue'),
      },
    ],
  },
];
