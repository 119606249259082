import { defineStore } from 'pinia';
import Api from '@/Api';
import { handleAxiosError } from '@/helpers/general-helpers';

export default defineStore('AuthenticatedUserStore', {
  state: () => ({
    intendedUrl: null,
    isLoggedIn: false,
    userData: {},
  }),

  getters: {
    fullName: (state) =>
      `${state.userData.first_name} ${state.userData.last_name}`,
  },

  actions: {
    checkIfLoggedIn() {
      return new Promise((resolve, reject) => {
        if (this.isLoggedIn) {
          resolve(true);
        }

        try {
          return Api.get('/settings')
            .then((response) => {
              this.isLoggedIn = true;
              this.userData = response.data.data;
              resolve(true);
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 503) {
                  document.body.innerHTML = `
                  <div class="flex items-center justify-center h-screen text-black bg-white">
                    <div class="text-center space-y-4">
                      <img src="https://ik.imagekit.io/wittypen/gifs/under-maintenance.gif" class="block mx-auto">
                      <h1 class="text-4xl font-bold">Under Maintenance</h1>
                      <p>
                      We are making some improvements to the platform. Please check back later.
                      </p>
                      <img src="https://wittypen.com/img/logo.svg" class="w-40 mx-auto" />
                    </div>
                  </div>
                `;
                }
              }

              resolve(false);
            });
        } catch {
          resolve(false);
        }
      });
    },

    setFirstName(first_name) {
      this.userData.first_name = first_name;
    },

    setLastName(last_name) {
      this.userData.last_name = last_name;
    },

    setProductTourPreference(tour_preference_status) {
      this.userData.preferences.product_tour = tour_preference_status;
    },

    logout() {
      return Api.post('/auth/logout')
        .then(() => {
          localStorage.clear();
          localStorage.setItem('logout-event', `logout${Math.random()}`);

          document.cookie =
            'wittypen_session_cookie=invalid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          document.cookie =
            'XSRF-TOKEN=invalid; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

          this.userData = {};
          this.isLoggedIn = false;
        })
        .catch((error) => {
          handleAxiosError(error);
        });
    },
  },
});
