export default [
  {
    path: '',
    name: 'AccountantLayout',
    component: () => import('@/views/accountant/Layout.vue'),
    children: [
      {
        path: '/accountant/home',
        name: 'AccountantHome',
        component: () => import('@/views/accountant/Home.vue'),
      },
      {
        path: '/accountant/projects',
        name: 'AccountantViewProjects',
        component: () => import('@/views/accountant/ViewProjects.vue'),
      },
      {
        path: '/accountant/single-orders',
        name: 'AccountantViewSingleOrders',
        component: () => import('@/views/accountant/ViewSingleOrders.vue'),
      },
      {
        path: '/accountant/writer-payouts',
        name: 'AccountantWriterPayouts',
        component: () => import('@/views/accountant/WriterPayouts.vue'),
      },
    ],
  },
];
