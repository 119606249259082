export default [
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('@/views/common/VerifyEmail.vue'),
  },
  {
    path: '/verify-contact-number',
    name: 'VerifyContactNumber',
    component: () => import('@/views/common/VerifyContactNumber.vue'),
  },
];
