export default [
  {
    path: '/admin/tasks/:id/document',
    name: 'AdminDocumentEditor',
    component: () => import('@/views/admin/DocumentEditor.vue'),
  },
  {
    path: '',
    name: 'AdminLayout',
    component: () => import('@/views/admin/Layout.vue'),
    children: [
      {
        path: '/admin/home',
        name: 'AdminHome',
        component: () => import('@/views/admin/Home.vue'),
      },
      {
        path: '/admin/activities-filter-view',
        name: 'AdminActivitiesFilterView',
        component: () => import('@/views/admin/ActivitiesFilterView.vue'),
      },
      {
        path: '/admin/first-submissions-tracker',
        name: 'AdminFirstSubmissionsTracker',
        component: () => import('@/views/admin/FirstSubTracker.vue'),
      },
      {
        path: '/admin/global-first-submissions-tracker',
        name: 'AdminGlobalFirstSubmissionsTracker',
        component: () => import('@/views/admin/GlobalFirstSubTracker.vue'),
      },
      {
        path: '/admin/notifications',
        name: 'AdminNotifications',
        component: () => import('@/views/admin/ViewNotifications.vue'),
      },
      {
        path: '/admin/activities',
        name: 'AdminActivities',
        component: () => import('@/views/admin/ViewActivities.vue'),
      },
      {
        path: '/admin/projects/:id',
        name: 'AdminProjectEditor',
        component: () => import('@/views/admin/ProjectEditor.vue'),
      },
      {
        path: '/admin/projects',
        name: 'AdminViewProjects',
        component: () => import('@/views/admin/ViewProjects.vue'),
      },
      {
        path: '/admin/tasks/:id',
        name: 'AdminViewTask',
        component: () => import('@/views/admin/ViewTask.vue'),
      },
      {
        path: '/admin/tasks',
        name: 'AdminViewTasks',
        component: () => import('@/views/admin/ViewTasks.vue'),
      },
      {
        path: '/admin/orders/:id',
        name: 'AdminViewOrder',
        component: () => import('@/views/admin/ViewOrder.vue'),
      },
      {
        path: '/admin/orders',
        name: 'AdminViewOrders',
        component: () => import('@/views/admin/ViewOrders.vue'),
      },
      {
        path: '/admin/new-project',
        name: 'AdminProjectCreator',
        component: () => import('@/views/admin/ProjectCreator.vue'),
      },
      {
        path: '/admin/global-checklist-editor',
        name: 'AdminGlobalChecklistEditor',
        component: () => import('@/views/admin/GlobalChecklistEditor.vue'),
      },
      {
        path: '/admin/writers/:id',
        name: 'AdminViewWriter',
        component: () => import('@/views/admin/ViewWriter.vue'),
      },
      {
        path: '/admin/writers',
        name: 'AdminViewWriters',
        component: () => import('@/views/admin/ViewWriters.vue'),
      },
      {
        path: '/admin/applicants/:id',
        name: 'AdminViewApplicant',
        component: () => import('@/views/admin/ViewApplicant.vue'),
      },
      {
        path: '/admin/applicants',
        name: 'AdminViewApplicants',
        component: () => import('@/views/admin/ViewApplicants.vue'),
      },
      {
        path: '/admin/Orders/:id',
        name: 'ViewOrder',
        component: () => import('@/views/admin/ViewOrder.vue'),
      },
      {
        path: '/admin/customers/:id',
        name: 'AdminViewCustomer',
        component: () => import('@/views/admin/ViewCustomer.vue'),
      },
      {
        path: '/admin/customers',
        name: 'AdminViewCustomers',
        component: () => import('@/views/admin/ViewCustomers.vue'),
      },
      {
        path: '/admin/companies/:id',
        name: 'AdminViewCompany',
        component: () => import('@/views/admin/ViewCompany.vue'),
      },
      {
        path: '/admin/companies',
        name: 'AdminViewCompanies',
        component: () => import('@/views/admin/ViewCompanies.vue'),
      },
      {
        path: '/admin/leaderboard',
        name: 'AdminLeaderboard',
        component: () => import('@/views/admin/Leaderboard.vue'),
      },
      {
        path: '/admin/broadcast',
        name: 'AdminBroadcastCreator',
        component: () => import('@/views/admin/BroadcastCreator.vue'),
      },
      {
        path: '/admin/coupons',
        name: 'AdminCouponManager',
        component: () => import('@/views/admin/CouponManager.vue'),
      },
      {
        path: '/admin/settings',
        name: 'AdminSettings',
        component: () => import('@/views/admin/Settings.vue'),
      },
      {
        path: '/admin/writer-payouts',
        name: 'AdminWriterPayouts',
        component: () => import('@/views/admin/WriterPayouts.vue'),
      },
      {
        path: '/admin/revision-tracker',
        name: 'AdminRevisionTracker',
        component: () => import('@/views/admin/RevisionTracker.vue'),
      },
      {
        path: '/admin/pending-order-tracker',
        name: 'AdminPendingOrderTracker',
        component: () => import('@/views/admin/PendingOrderTracker.vue'),
      },
      {
        path: '/admin/accepted-order-tracker',
        name: 'AdminAcceptedOrderTracker',
        component: () => import('@/views/admin/AcceptedOrderTracker.vue'),
      },
      {
        path: '/admin/global-revision-tracker',
        name: 'AdminGlobalRevisionTracker',
        component: () => import('@/views/admin/GlobalRevisionTracker.vue'),
      },
      {
        path: '/admin/invite-customer',
        name: 'AdminCustomerInviteTool',
        component: () => import('@/views/admin/CustomerInviteTool.vue'),
      },
      {
        path: '/admin/pending-sample-articles-tracker',
        name: 'AdminPendingSampleArticlesTracker',
        component: () =>
          import('@/views/admin/PendingSampleArticlesTracker.vue'),
      },
      {
        path: '/admin/writers/:writerId/pending-sample-articles/:articleId',
        name: 'AdminPendingSampleArticleView',
        component: () => import('@/views/admin/PendingSampleArticle.vue'),
      },
    ],
  },
];
