<template>
  <RouterView />
</template>

<style lang="scss">
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
@import '@/styles/app.scss';
</style>

<script setup>
import { RouterView } from 'vue-router';
import { useTitle } from '@vueuse/core';

useTitle('Wittypen');
</script>
